(function() {
  $(document).ready(function() {
    'use strict';
    var resize_content, resize_content_timeout;
    $('.navbar').navbar();
    $('.navbar').morphDropdown();
    $('.sidebar').sidebar();
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover({
      trigger: 'hover'
    });
    resize_content_timeout = void 0;
    resize_content = function() {
      $('.content').css({
        'min-height': $(window).height() - $('.navbar').height()
      });
    };
    $(window).resize(function() {
      resize_content_timeout = clearTimeout(resize_content_timeout);
      setTimeout(resize_content, 100);
    });
    resize_content();
    if ($('.wizard .content').length > 0) {
      $('.wizard .content').perfectScrollbar();
    }
    if ($('.morph-gallery').length > 0) {
      $('.morph-gallery').perfectScrollbar();
    }
  });

  $(window).on('load', function() {
    var loader;
    loader = $('.loader-wrapper');
    TweenLite.to(loader, 0.5, {
      opacity: 0,
      onComplete: function() {
        loader.remove();
      }
    });
  });

}).call(this);
